<template>
  <div class="right_wrap">
    <div class="right_item" @click="handleToGuide()">
      <div>
        <img :src="rightGuide" />
      </div>
      <span>入驻指南</span>
    </div>

    <div class="right_white_line"></div>

    <div class="right_item" @click="handleToCaApply()">
      <div>
        <img :src="rightCA" />
      </div>
      <span>CA办理</span>
    </div>

    <div class="right_white_line"></div>

    <div class="right_item right_item1" @click="handleToHelpCenter()">
      <div>
        <img :src="rightTeach" style="margin:0 10px" />
      </div>
      <span>帮助中心</span>
    </div>

    <div class="right_white_line"></div>
    <div id="telephone" class="phone notshow" style="position: absolute;" @mouseover="Over" @mouseout="Out">
      <div class="p5">市场拓展部(入驻审核)：0513-68069808</div>
      <div class="p5">招标采购部(软件咨询)：0513-68063080</div>
      <div class="p5">CA办理咨询:18906291896</div>
    </div>
    <div style="background: #009FFF;" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
      <div>
        <img :src="rightPhoe" style="width: 40px;height: auto;margin:0 10px" />
      </div>
      <!-- <span>0513 68069808</span> -->

    </div>
    <!-- <div class="right_item right_item2">
      <div>
        <img :src="rightPhoe" style="width: 40px;height: auto;margin:0 10px" />
      </div>
       <span>0513 68069808</span>
    </div> -->

    <div class="right_white_line"></div>

    <div class="right_item right_item3" @click="handleToHelpCenter()">
      <div>
        <img :src="rightDown" style="margin:0 10px" />
      </div>
      <span>工具下载</span>
    </div>

    <div class="right_white_line"></div>

    <div class="right_item" @click="backTop">
      <div>
        <img :src="rightUp" />
      </div>
    </div>
  </div>
</template>


<script>
import imgData from "@/data.js";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { mainStore } from "@/store";

export default {
  setup() {
    const data = reactive({});
    return {
      ...data,
      ...imgData,
      scrollTop: 0,
      navgatorPath: '',
      // listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop); //监听滚动条
    if (localStorage.getItem('toHelpCenter') == 2) {
      localStorage.removeItem('toHelpCenter')
      this.$parent.toHelpCenter()
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollToTop); //移除滚动条监听
  },
  methods: {
    Over() {
      var over = document.getElementById('telephone')
      over.classList.remove("notshow")
    },
    Out() {
      var over = document.getElementById('telephone')
      over.classList.add("notshow")
    },
    handleMouseOver() {
      var over = document.getElementById('telephone')
      over.classList.remove("notshow")
    },
    handleMouseOut() {
      var over = document.getElementById('telephone')
      over.classList.add("notshow")
    },
    handleToGuide() {
      window.open("https://www.hhzb.com.cn/InfoView?id=1626055807232741377")
    },
    handleToCaApply() {
      const store = mainStore();
      if (window.location.pathname.indexOf('CaApply') === -1) {
        this.$router.push('/CaApply');
        store.setactivePageUrl('/CaApply');
      }
    },
    handleToHelpCenter() {
      const store = mainStore();
      const router = useRouter();
      if (window.location.pathname.indexOf('index') != -1) {
        this.$parent.toHelpCenter()
      } else {
        this.$router.push('/index');
        localStorage.setItem('toHelpCenter', 2)
        store.setactivePageUrl('/index');
      }
    },
    toBottom() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(document.documentElement.scrollHeight / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === document.documentElement.scrollHeight - document.documentElement.clientHeight) {
          clearInterval(timer);
        }
      }, 30);
    },
    // 监听到的滚动的距离
    scrollToTop() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    // 点击回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
      // 直接上移至顶部
      // document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  }
};
</script>

<style >
@import url("./RightNav.css");
</style>